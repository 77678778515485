import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  EventItem,
  PickleballCourt,
  Place,
  PlaceFollower,
  PlaceGroup,
} from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthEventItemModel } from '@sentinels/models';
import { GthPickleballPlaceModel, GthPlaceModel } from '@sentinels/models/place';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { List } from 'immutable';
import { from, lastValueFrom, mergeMap, Observable, of, switchMap } from 'rxjs';
import { zip } from 'rxjs';
import { map, shareReplay, startWith, toArray } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';
import { applyLocationMapsBounds } from '../helpers/geobounds';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class PlacesService extends FirestoreService<Place> {
  protected basePath: string = 'places';
  private readonly placesPageViewShards = 5;
  private usersService = inject(UserService);

  async addFollower(userId: string, place: Place): Promise<boolean> {
    if (!place?.id) return false;

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      return placeRef
        .collection('followers')
        .doc<PlaceFollower>(userId)
        .set({
          created: firebase.firestore.FieldValue.serverTimestamp(),
          placeId: place.id,
          placeRef: placeRef.ref,
          userId,
        })
        .then(() => true)
        .catch((error) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error('PlacesService#addFollower error', error);

      return false;
    }
  }

  async removeFollower(userId: string, place: Place): Promise<boolean> {
    if (!place?.id) return false;

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      const followerRef = placeRef.collection<PlaceFollower>('followers').doc(userId);

      const followerSnap = await lastValueFrom(followerRef.get());

      if (!followerSnap.exists) return false;

      return followerRef
        .delete()
        .then(() => true)
        .catch((error) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error('PlacesService#removeFollower error', error);

      return false;
    }
  }

  getFollowers$(place: Place): Observable<PlaceFollower[]> {
    if (!place?.id) return of([]);

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      return placeRef.collection<PlaceFollower>('followers').valueChanges({ idField: 'id' });
    } catch (error: unknown) {
      console.error('PlacesService#getFollowers$ error', error);

      return of([]);
    }
  }

  async addGroup(group: PlaceGroup, place: Place): Promise<string> {
    if (!place?.id) return '';

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      return placeRef
        .collection<PlaceGroup>('groups')
        .add(group)
        .then((d) => d.id)
        .catch((error) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error('PlacesService#addGroup error', error);

      return '';
    }
  }

  async removeGroup(groupId: string, place: Place): Promise<boolean> {
    if (!place?.id) return false;

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      const groupRef = placeRef.collection('groups').doc(groupId);

      const groupSnap = await lastValueFrom(groupRef.get());

      if (!groupSnap.exists) return false;

      return groupRef
        .delete()
        .then(() => true)
        .catch((error) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error('PlacesService#removeGroup error', error);

      return false;
    }
  }

  getGroups$(place: Place): Observable<PlaceGroup[]> {
    if (!place?.id) return of([]);

    try {
      const placeRef = this.collection.doc<Place>(place.id);

      return placeRef.collection<PlaceGroup>('groups').valueChanges({ idField: 'id' });
    } catch (error: unknown) {
      console.error('PlacesService#getGroups$ error', error);

      return of([]);
    }
  }

  getVisitors$(place: Place): Observable<string[]> {
    if (!place?.id) return of([]);

    return this.usersService.getUsersByCheckIn(place.id).pipe(
      map((users) => {
        return users.map((user) => user.uid);
      }),
    );
  }

  getPlaceByLocation$(bounds: google.maps.LatLngBoundsLiteral): Observable<GthPlaceModel[]> {
    const collection = DBUtil.Places;

    interface PlacesItemWithId extends Place {
      id: string;
    }

    return from(
      this.firestore
        .collection(collection, (ref) => {
          return applyLocationMapsBounds(ref, bounds, 'address');
        })
        .get(),
    ).pipe(
      map((snapshot) => {
        // Convert the snapshot docs to an Immutable List
        const placesList = List(
          snapshot.docs.map((doc) => {
            const placeData = doc.data() as Place;
            return { ...placeData, id: doc.id } satisfies PlacesItemWithId;
          }),
        );

        // Filter and map using Immutable.js methods
        // Convert the Immutable List back to a plain array
        return placesList.map((place) => new GthPlaceModel(place.id, place)).toArray();
      }),
      shareReplay(1),
    );
  }

  getPickleballPlacesByLocation(
    bounds: google.maps.LatLngBoundsLiteral,
  ): Observable<GthPickleballPlaceModel[]> {
    return this.getPlaceByLocation$(bounds).pipe(
      switchMap((places) => {
        return from(places).pipe(
          mergeMap((place) => {
            return zip([
              of(place),
              this.getVisitors$(place).pipe(startWith([])),
              this.getFollowers$(place).pipe(startWith([])),
              this.getGroups$(place).pipe(startWith([])),
            ]).pipe(
              map(([place, visitors, followers, groups]) => {
                place.visitors = visitors;
                place.followers = followers;
                place.groups = groups;

                return new GthPickleballPlaceModel(place.id, place);
              }),
            );
          }),
          toArray(),
          map((placesWithDetails) => {
            // Sort places by the number of groups first, then visitors
            return placesWithDetails.sort((a, b) => {
              const groupDifference = b.groups.length - a.groups.length;
              if (groupDifference !== 0) {
                return groupDifference;
              }
              return b.visitors.length - a.visitors.length;
            });
          }),
          // Limit the results to 100 after sorting
          map((sortedPlaces) => sortedPlaces.slice(0, 100)),
        );
      }),
    );
  }

  addPlace(place: Place) {
    return from(this.addPlaceAsync(place));
  }

  updatePlace(place: Place) {
    return from(this.updatePlaceAsync(place));
  }

  getPlaceById$(id: string, viewRevision = false) {
    return from(this.getPlaceByIdAsync(id, viewRevision));
  }

  async approveAsync(id: string, revisionId?: string) {
    const uri = this.getFunctionUri(`approve`);
    const callable = httpsCallableFromURL(getFunctions(), uri);
    try {
      await callable({
        id,
        revisionId,
      });

      return true;
    } catch {
      console.error('Error approving place by id');
      return false;
    }
  }

  async denyAsync(id: string, reason: string, revisionId?: string) {
    const uri = this.getFunctionUri(`deny`);
    const callable = httpsCallableFromURL(getFunctions(), uri);
    try {
      await callable({
        id,
        reason,
        revisionId,
      });

      return true;
    } catch {
      console.error('Error approving place by id');
      return false;
    }
  }

  // eslint-disable-next-line max-len
  private async getPlaceByIdAsync(id: string, viewRevision = false) {
    const uri = this.getFunctionUri(`read`);
    const callable = httpsCallableFromURL(getFunctions(), uri);
    try {
      const response = await callable({
        id,
        viewRevision,
      });

      let data = response?.data as Place;
      if (!data) return undefined;
      if (viewRevision && Array.isArray(data.pendingChanges)) {
        data.pendingChanges = data.pendingChanges
          .map((c) => {
            // eslint-disable-next-line max-len
            const timestamp = new Timestamp(
              c.lastUpdatedOn._seconds,
              c.lastUpdatedOn._nanoseconds,
            );
            return {
              ...c,
              lastUpdatedOn: timestamp.toDate(),
            };
          })
          .sort((a, b) => b.lastUpdatedOn.getTime() - a.lastUpdatedOn.getTime());

        const id = data.id;
        data = {
          ...data,
          ...data.pendingChanges[0],
          id,
        };
      }
      if (data.pickleballMetadata) {
        return new GthPickleballPlaceModel(
          id,
          data as Place & { pickleballMetadata: PickleballCourt },
        );
      } else {
        return new GthPlaceModel(id, data);
      }
    } catch {
      console.error('Error reading place by id');
      return undefined;
    }
  }

  private async addPlaceAsync(place: Place) {
    const uri = this.getFunctionUri(`add`);
    const callable = httpsCallableFromURL(getFunctions(), uri);
    const response = await callable({
      ...place,
    });
    if (response?.data) {
      return true;
    }

    return false;
  }

  private async updatePlaceAsync(place: Place) {
    if (!place.id) return false;
    const uri = this.getFunctionUri(`update`);
    const callable = httpsCallableFromURL(getFunctions(), uri);
    const response = await callable({
      ...place,
    });
    if (response?.data) {
      return true;
    }

    return false;
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/places-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }

  getEventsByPlaceId(placeId: string): Observable<GthEventItemModel[]> {
    return this.firestore
      .collection<EventItem>('events', (ref) => ref.where('placeId', '==', placeId))
      .valueChanges({ idField: 'id' })
      .pipe(
        map((events) =>
          events.map((event) => {
            return new GthEventItemModel(event.id, event);
          }),
        ),
      );
  }
}
